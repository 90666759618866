import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { redirect, RouteObject, useLocation, useNavigate } from 'react-router-dom';
import axiosInstance from 'src/helper/AxiosInstance';
import { storeToken, saveUserInfo } from 'src/reducers/auth';
import { RootState } from 'src/store';
import { authenticationRoutes, names } from 'src/helper/routes';
import axios from 'axios';
import parseJwt from 'src/helper/parseJwt';

function useAuth(): boolean {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { token } = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const location = useLocation();

  // Check if the current path is /reset-password
  const isResetPasswordRoute =
    location.pathname === '/reset-password' || location.pathname === '/forgot-password';
    useEffect(() => {
      (async () => {
        const stored_token = token || sessionStorage.getItem('@token');
    
        if (!stored_token) {
          dispatch(storeToken(null));
          setIsLoading(false);
          if (!isResetPasswordRoute) {
            navigate('/account/login', {
              replace: true
            });
          }
          return;
        }
    
        // تأكد من أن token متاح قبل استدعاء GetUserInfo
        if (stored_token) { 
          await GetUserInfo(stored_token);
        }
      })();
    }, [token]);
    

  const { user, token: userToken } = useSelector((state: RootState) => state.auth);

  
 
  async function GetUserInfo(token?: string) {
    try {
      setIsLoading(true);
      const payload = parseJwt(userToken);
      
     
    const res = await axios.get(`https://api.focalpoint-apps.com/profile/${payload?.id}`);
      // test/
        // const res = await axios.get(`http://localhost:3004/profile/${payload?.id}`);

      // const { data } = await axiosInstance.get('profile');

      const data = res?.data?.profile;
      console.log('aa', data);
      dispatch(storeToken(token));
      dispatch(
        saveUserInfo({
          ...data
        })
      );

      if (authenticationRoutes.some((route: RouteObject) => [route.path].includes(pathname))) {
        return navigate('/welcome', {
          replace: true
        });
      }
      navigate(pathname + location.search, {
        replace: true
      });
    } catch (error) {
      dispatch(storeToken(null));
      console.log(error);
      // window.location.reload();
    } finally {
      setIsLoading(false);
    }
  }

  type Types = 'permissions' | 'access';
  type UserTypes = 'super_admin' | 'admin' | 'salesman' | 'accountant';

  const GeneratePermissionPerAccess = useCallback(function (type: UserTypes) {
    let result: Record<Types, string[]> = {
      access: [],
      permissions: []
    };

    switch (type) {
      case 'super_admin':
        result = {
          permissions: names,
          access: ['show', 'edit', 'delete', 'update']
        };
        return GeneratePermissions(result.permissions, result.access);
        break;
      case 'salesman':
        result = {
          permissions: [],
          access: []
        };

        return GeneratePermissions(result.permissions, result.access);
        break;
      case 'accountant':
        result = {
          permissions: ['invoices', 'products'],
          access: ['show', 'edit', 'delete', 'update']
        };

        return GeneratePermissions(result.permissions, result.access);
        break;

      default:
        break;
    }
  }, []);

  const GeneratePermissions = useCallback(function (permissions: string[], access: string[]) {
    let result: string[] = [],
      collectPermissions: any[];

    collectPermissions = new Array().concat(
      ...permissions.map((name: string) => {
        let item: string[];

        item = access.map((access: string) => access + ' ' + name);
        return item;
      })
    );

    return collectPermissions;
  }, []);

  return isLoading;
}

export default useAuth;
