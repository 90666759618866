import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Icon } from '@iconify/react';
import SideModal from 'src/components/shared/SideModal';
import Table from 'src/components/shared/tables';
import axiosInstance from 'src/helper/AxiosInstance';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { ChangeCustomizeKeys, UpdateFilter, UpdateResult } from 'src/reducers/users';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import SharedTime from 'src/components/shared/SharedTime';
import SortButton from 'src/components/shared/SortButton';
import { useAlert } from 'src/hooks/alerts';
import { AxiosError } from 'axios';
import generateAlert from 'src/helper/generateAlert';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import RangePicker from 'src/components/shared/RangePicker';
import { formatEndDay, formatStartDay } from 'src/helper/convertToUTCTimestamp';
import { FormattedMessage, useIntl } from 'react-intl';
import { getDates } from 'src/helper/DateUtils';
import usePeriods from 'src/hooks/usePeriods';
import { Fields, IFilters } from 'src/types/types';
import { Responsive } from 'src/layouts/Responsive';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import Card from 'src/components/shared/Card';
import img_1 from '../images/Union.png';
import img_1_2 from '../images/Union@2x.png';
import img_1_3 from '../images/Union@3x.png';
import img_2 from '../images/UnionY.png';
import img_2_2 from '../images/UnionY@2x.png';
import img_2_3 from '../images/UnionY@3x.png';
import img_5 from '../images/ban (1) 1.png';
import img_5_2 from '../images/ban (1) 1@2x.png';
import img_5_3 from '../images/ban (1) 1@3x.png';
import useConfirmationDialog from 'src/hooks/useConfirmation';
import { toast } from 'react-toastify';
import * as xlsx from 'exceljs';
function useHooks() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  const { user } = useSelector((state: RootState) => state.auth);
  const date = searchParams.get('date');
  console.log('date=====>', date);

  const type = searchParams.get('type');

  const [userLang, setUserLang] = useState('');
  const [isLangUpdated, setIsLangUpdated] = useState(false);
  const [initialQueries, setInitialQueries] = useState<any>([]);

  // Update userLang based on user.Language
  useEffect(() => {
    if (user?.Language?.length === 1) {
      const lang =
        user.Language[0] === 'English'
          ? 'en'
          : user.Language[0] === 'Arabic'
          ? 'ar'
          : user.Language[0] === 'French'
          ? 'fr'
          : 'ar';
      setUserLang(lang);
      setIsLangUpdated(true);
    } else {
      setUserLang(''); // Reset userLang if conditions are not met
      setIsLangUpdated(true);
    }
  }, [user?.Language]);

  console.log('userLang=====>', userLang);

  // Update initialQueries based on userLang and currentDashboard
  useEffect(() => {
    if (isLangUpdated) {
      let queries;

      if (user?.Language?.length === 1) {
        queries = [
          ['userType', '==', 'SUPPORT'],
          ['userLang', '==', userLang]
        ];
      } else {
        queries = [['userType', '==', 'SUPPORT']];
      }

      setInitialQueries(queries);
    }
  }, [currentDashboard, userLang, isLangUpdated]);

  console.log('initialQueries=====>', initialQueries);

  let rerender: boolean = true;
  const { filters: userFilters } = useSelector((state: RootState) => state.Users);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  const { from, to } = getDates('today');

  const [filters, setFilters] = useState<IFilters>({
    ...userFilters,
    page: 1,
    perPage: 14,
    sorts: [],
    queries: [],
    search: undefined
  });

  // Update filters when initialQueries is ready
  useEffect(() => {
    if (initialQueries.length > 0) {
      const initialFilters = date
        ? [...initialQueries, ['createdDateValue', '==', `${from}-${to}`]]
        : initialQueries;

      setFilters((prevFilters) => ({
        ...prevFilters,
        queries: initialFilters
      }));
    }
  }, [initialQueries, date]);

  useEffect(() => {
    if (!rerender || filters.queries.length === 0) return;
    GetUsers();
    rerender = false;
  }, [filters, searchParams]);

  console.log('sorts', filters.sorts);

  async function GetUsers(params?: Partial<IFilters>) {
    try {
      setIsLoading(true);
      const parameters = {
        ...filters,
        ...params,
        sorts: isModalOpen ? [] : filters.sorts // Initialize sorts array when modal is open
      };
      console.log('paramters?', parameters);
      const { data } = await axiosInstance.get('users', {
        params: parameters
      });
      console.log('data===============>', data);

      dispatch(UpdateResult(data.result));
    } catch (error: AxiosError | any) {
      const err = error?.response?.data;
      const message = err?.result;
      const details = err?.result?.details;
      const msg = details || message || 'There is something went wrong while getting data..';
      generateAlert(msg, 'error');
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  const ChangeFilter = React.useCallback(
    (value: any, key: keyof IFilters | 'clear') => {
      if (key === 'clear') {
        const result = {
          ...filters,
          page: 1,
          perPage: 14,
          sorts: [],
          queries: [...initialQueries],
          search: undefined
        };
        setFilters(result);
        dispatch(UpdateFilter(result));
        return;
      }

      let result: any = { ...filters };
      switch (key) {
        case 'search':
          result[key] = value;
          break;
        case 'page':
          result[key] = value;
          break;
        case 'perPage':
          result[key] = value;
          break;
        case 'sorts':
          if (value === 'clearSorts') {
            result[key] = [];
          } else {
            const [_sortKey, _sortValue] = value;
            result[key] = [[_sortKey, _sortValue]];
          }
          break;
        case 'queries':
          result[key] = value;
          result['page'] = 1;
          break;
        default:
          break;
      }
      const sorts = isModalOpen ? [] : result.sorts;

      setFilters({ ...result, sorts });
      dispatch(UpdateFilter({ ...result, sorts }));
    },
    [filters, initialQueries, isModalOpen, dispatch]
  );

  return {
    isLoading,
    ChangeFilter,
    filters,
    setIsModalOpen,
    initialQueries
  };
}

export default function index() {
  const [newData, setNewData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const { width, height } = useWindowDimensions();
  const [exportDisabled, setExportDisabled] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(true);
  const [title, setTitle] = useState<any>('allSupport');
  const [start, setStart] = useState<Date>();
  const [end, setEnd] = useState<Date>();

  const intl = useIntl();
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState<string>('filter');
  const tabs = [
    {
      name: useIntl().formatMessage({ id: 'customize', defaultMessage: 'Customize fields' }),
      key: 'customize'
    },
    { name: useIntl().formatMessage({ id: 'filter', defaultMessage: 'Filter' }), key: 'filter' }
  ];
  const { isLoading, ChangeFilter, filters, setIsModalOpen, initialQueries } = useHooks();
  const { keys, customize_keys, data, pagination, count } = useSelector(
    (state: RootState) => state.Users
  );
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  const isIncluded = React.useCallback(
    (key: string): boolean => customize_keys.includes(key),
    [customize_keys]
  );

  const supportMessage = 'allSupport';
  const todayMessage = 'today';
  const last7DaysMessage = 'last 7 days';
  const last30DaysMessage = 'last 30 days';
  const dateRangeMessage = 'dateRange';

  useEffect(() => {
    if (start && end) {
      handleDateOptionClick('Date range', dateRangeMessage);
    }
  }, [start, end]);

  const handleDateOptionClick = (option, title) => {
    setTitle(title);

    let filterQueries = [...initialQueries]; // Start with initial queries

    // Check if the option is 'All User'
    if (option === 'All technical support') {
      // If 'All User', no date filtering is applied

      ChangeFilter(null, 'clear');
      return; // Exit the function early
    }

    const currentDate = new Date();
    let startDate, endDate;

    switch (option) {
      case 'Today':
        startDate = new Date(currentDate);
        endDate = new Date(currentDate);
        break;
      case 'Last 7 days':
        startDate = new Date(currentDate);
        startDate.setDate(startDate.getDate() - 7);
        endDate = new Date(currentDate);
        break;
      case 'Last 30 days':
        startDate = new Date(currentDate);
        startDate.setDate(startDate.getDate() - 30);
        endDate = new Date(currentDate);
        break;

      case 'Date range':
        if (start && end) {
          startDate = new Date(start);
          endDate = new Date(end);
        }
        break;

      default:
        startDate = null;
        endDate = null;
        break;
    }

    // Apply date filtering if startDate and endDate are defined
    if (startDate && endDate) {
      ChangeFilter(
        [
          ['createdDateValue', '==', `${formatStartDay(startDate)}-${formatEndDay(endDate)}`],
          ...initialQueries
        ],
        'queries'
      );
    }
  };

  const ChangeCustomizeKey = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    dispatch(ChangeCustomizeKeys({ value, checked }));
  }, []);

  async function ExportXlsx(currentData, currentKeys) {
    try {
      setExportDisabled(true);
      const { data } = await axiosInstance.post(
        'users/export',
        { currentData, currentKeys },
        { responseType: 'blob' }
      );

      const filename = new Date().getTime() + '.xlsx';
      const blob = new Blob([data]);
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.click();
      URL.revokeObjectURL(url);
      a.remove();

      // console.log(url)
    } catch (error) {
      console.log(error);
    } finally {
      setExportDisabled(false);
    }
  }
  async function fetchData() {
    try {
      setLoading(true);
      const res = await axiosInstance.get('dashboard/support-count');
      setNewData(res.data.result.data);
      setLoading(false);
      console.log('data', res.data.result.data);
    } catch (error) {
      console.error('Error fetching today orders:', error);
    }
  }
  useEffect(() => {
    if (currentDashboard === 'nikah') {
      fetchData();
    }
  }, [currentDashboard]);
  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }
  const showConfirmation = useConfirmationDialog();
  const handelUpdataUser = async (id) => {
    const confirmationMsg = intl.formatMessage({
      id: 'eiditUser',
      defaultMessage: ' Are you sure you want to edit this user?'
    });
    setLoading(true);
    showConfirmation(confirmationMsg, async () => {
      try {
        const res = await axiosInstance.patch(`/users/${id}`, { userType: 'USER' });
        const successMessage = intl.formatMessage({
          id: 'eiditUserMsg',
          defaultMessage: 'User has been eidited successfully'
        });
        toast.success(successMessage);
      } catch (error) {
        console.error('Error fetching today orders:', error);
      } finally {
        ChangeFilter(null, 'clear');
        setLoading(false);
      }
    });
  };
  async function ExportAllDataXlsx() {
    try {
      setExportDisabled(true);

      // طلب جميع البيانات بدون تطبيق `perPage`
      const res = await axiosInstance.get('users', {
        params: {
          ...filters,
          page: 1,
          perPage: count // طلب جميع البيانات
        }
      });

      // تحويل الكائنات إلى نصوص لتجنب [object Object]
      const processedData = res.data.result.data?.map((item: any) => {
        const newItem = { ...item };
        // تحويل أي كائنات داخل البيانات إلى نصوص JSON أو استخراج الحقول المطلوبة
        Object.keys(newItem).forEach((key) => {
          if (typeof newItem[key] === 'object' && newItem[key] !== null) {
            // تحويل الكائنات إلى نصوص JSON
            newItem[key] = JSON.stringify(newItem[key]);
          }
        });
        return newItem;
      });

      // تحديد المفاتيح المطلوبة فقط بناءً على `customize_keys`
      const columnsToExport = customize_keys.map((key: string) => ({
        header: key,
        key: key,
        width: key === 'createdDate' ? 10 : 20 // تقليل عرض createdDate
      }));

      // إنشاء ملف Excel من البيانات المعالجة
      const workbook = new xlsx.Workbook();
      const worksheet = workbook.addWorksheet('All Data');

      // إضافة الأعمدة بناءً على المفاتيح في `customize_keys`
      worksheet.columns = columnsToExport;

      // إضافة الصفوف من البيانات المعالجة
      processedData.forEach((item: any) => {


        // إنشاء صف فقط مع المفاتيح التي تتوافق مع `customize_keys`
        const rowData = customize_keys.reduce((obj: any, key: string) => {
          if (key === 'name') {
            // تأكد من أن consultName هو كائن وليس سلسلة نصية
            if (item?.consultName) {
              // إذا كانت consultName سلسلة نصية، قم بتحويلها مرة أخرى إلى كائن
              const consultName = typeof item.consultName === 'string'
                ? JSON.parse(item.consultName)
                : item.consultName;
  
              
              obj[key] = consultName.nameAr || 'N/A';
            } else {
              console.log('consultName is missing for item:', Object(item?.consultName));
              obj[key] = 'N/A'; // إرجاع قيمة فارغة إذا كانت consultName مفقودة
            }
          } else if (key === 'createdDate') {
            // تحويل createdDateValue إلى تاريخ
            const createdDate = new Date(item?.createdDateValue);
            obj[key] = !isNaN(createdDate.getTime()) ? createdDate.toLocaleDateString() : 'N/A'; // تنسيق التاريخ
          } else {
            obj[key] = item[key] || 'N/A'; // إضافة القيم المطلوبة فقط
          }
          return obj;
        }, {});
        worksheet.addRow(rowData);
      });

      // إرسال الملف كـ Excel
      const filename = new Date().getTime() + '_all_data.xlsx';
      await workbook.xlsx.writeBuffer().then((buffer: any) => {
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();
        URL.revokeObjectURL(url);
        a.remove();
      });
    } catch (error) {
      console.log(error);
    } finally {
      setExportDisabled(false);
    }
  }
  return (
    <div className="p-[3px] pt-12 space-y-4 bg-[#fafafa] min-h-screen overflow-x-hidden ">
      {/* <TableCustomizeFilterModal
        SendPrams={(val: any) => ChangeFilter(val, 'queries')}
        params={filters}
        setIsModalOpen={setIsModalOpen}
        initialQueries={initialQueries}
      /> */}
      {currentDashboard === 'nikah' && (
        <div
          className="flex flex-row justify-start items-start   ml-[24px]  mt-[24px] mr-0 mb-[32px]  p-0 pr-[20px]"
          style={{
            width: `${Responsive.w(1560, width)}px`,
            height: `${Responsive.h(125, height)}px`,
            gap: `${Responsive.sp(24, width)}px`
          }}
        >
          <Card
            className="flex  justify-between items-center  py-[24px] flex-grow-[1px] px-[16px] bg-white border-solid border-b-4 border-b-[#4ad991] rounded-[16px]"
            style={{
              width: `${Responsive.w(490, width)}px`,
              height: `${Responsive.h(125, height)}px`
            }}
          >
            <div
              className=" flex-grow-0 gap-[8px] p-0 flex flex-col justify-start items-start"
              style={{
                width: `${Responsive.w(163, width)}px`,
                height: `${Responsive.h(79, height)}px`
              }}
            >
              <p
                className="nowrap opacity-70   text-[16px] font-[Montserrat] flex-grow-0 text-[#202224] font-medium text-left"
                style={{
                  width: `${Responsive.w(163, width)}px`,
                  height: `${Responsive.h(34, height)}px`,
                  fontSize: `${Responsive.sp(16, width)}px`
                }}
              >
                <FormattedMessage
                  id="activeArabicFreelancers"
                  defaultMessage="Active Arabic Freelancers"
                />
              </p>
              <p
                className="tracking-[1px]  text-[28px] font-[Montserrat] flex-grow-0 text-[#202224] font-semibold text-left "
                style={{
                  width: `${Responsive.w(163, width)}px`,
                  height: `${Responsive.h(40, height)}px`,
                  fontSize: `${Responsive.sp(28, width)}px`
                }}
              >
                {loading ? (
                  <div className=" flex justify-center items-center">
                    <Spinner />
                  </div>
                ) : (
                  newData?.arabicConsultants
                )}
              </p>
            </div>
            <div
              className=" flex-grow-0 gap-[8px] p-0 flex flex-col justify-center items-center rounded-[10px] bg-[#d9f7e8]"
              style={{
                width: `${Responsive.w(42, width)}px`,
                height: `${Responsive.h(42, height)}px`
              }}
            >
              <img
                src={img_1}
                srcSet={`${img_1_2}, ${img_1_3}`}
                className="object-contain p-0 flex items-start justify-start flex-col flex-grow-0 "
                alt="Description of the image"
                style={{
                  width: `${Responsive.w(30, width)}px`,
                  height: `${Responsive.h(33.3, height)}px`
                }}
              />
            </div>
          </Card>

          <Card
            className="flex  justify-between items-center  py-[24px] flex-grow-[1px] px-[16px] bg-white border-solid border-b-4 border-b-[#e6bc59] rounded-[16px]"
            style={{
              width: `${Responsive.w(490, width)}px`,
              height: `${Responsive.h(125, height)}px`
            }}
          >
            <div
              className=" flex-grow-0 gap-[8px] p-0 flex flex-col justify-start items-start"
              style={{
                width: `${Responsive.w(163, width)}px`,
                height: `${Responsive.h(79, height)}px`
              }}
            >
              <p
                className="nowrap opacity-70   text-[16px] font-[Montserrat] flex-grow-0 text-[#202224] font-medium text-left"
                style={{
                  width: `${Responsive.w(163, width)}px`,
                  height: `${Responsive.h(34, height)}px`,
                  fontSize: `${Responsive.sp(16, width)}px`
                }}
              >
                <FormattedMessage
                  id="activeEnglishFreelancers"
                  defaultMessage="Active English Freelancers"
                />
              </p>
              <p
                className="tracking-[1px]  text-[28px] font-[Montserrat] flex-grow-0 text-[#202224] font-semibold text-left "
                style={{
                  width: `${Responsive.w(163, width)}px`,
                  height: `${Responsive.h(40, height)}px`,
                  fontSize: `${Responsive.sp(28, width)}px`
                }}
              >
                {loading ? (
                  <div className=" flex justify-center items-center">
                    <Spinner />
                  </div>
                ) : (
                  newData?.englishConsultants
                )}{' '}
              </p>
            </div>
            <div
              className=" flex-grow-0 gap-[8px] p-0 flex flex-col justify-center items-center rounded-[10px] bg-[#fff3d6]"
              style={{
                width: `${Responsive.w(42, width)}px`,
                height: `${Responsive.h(42, height)}px`
              }}
            >
              <img
                src={img_2}
                srcSet={`${img_2_2}, ${img_2_3}`}
                className="object-contain p-0 flex items-start justify-start flex-col flex-grow-0 "
                alt="Description of the image"
                style={{
                  width: `${Responsive.w(30, width)}px`,
                  height: `${Responsive.h(33.3, height)}px`
                }}
              />
            </div>
          </Card>

          <Card
            className="flex  justify-between items-center  py-[24px] flex-grow-[1px] px-[16px] bg-white border-solid border-b-4 border-b-[#f93c65] rounded-[16px]"
            style={{
              width: `${Responsive.w(490, width)}px`,
              height: `${Responsive.h(125, height)}px`
            }}
          >
            <div
              className=" flex-grow-0 gap-[8px] p-0 flex flex-col justify-start items-start"
              style={{
                width: `${Responsive.w(163, width)}px`,
                height: `${Responsive.h(79, height)}px`
              }}
            >
              <p
                className="nowrap opacity-70   text-[16px] font-[Montserrat] flex-grow-0 text-[#202224] font-medium text-left"
                style={{
                  width: `${Responsive.w(163, width)}px`,
                  height: `${Responsive.h(40, height)}px`,
                  fontSize: `${Responsive.sp(16, width)}px`
                }}
              >
                <FormattedMessage
                  id="inactiveFreelancers"
                  defaultMessage="Inactive Freelancers"
                />
              </p>
              <p
                className="tracking-[1px]  text-[28px] font-[Montserrat] flex-grow-0 text-[#202224] font-semibold text-left "
                style={{
                  width: `${Responsive.w(163, width)}px`,
                  height: `${Responsive.h(34, height)}px`,
                  fontSize: `${Responsive.sp(28, width)}px`
                }}
              >
                {loading ? (
                  <div className=" flex justify-center items-center">
                    <Spinner />
                  </div>
                ) : (
                  newData?.inactiveConsultants
                )}
              </p>
            </div>
            <div
              className=" flex-grow-0 gap-[8px] p-0 flex flex-col justify-center items-center rounded-[10px] bg-[#ffebeb]"
              style={{
                width: `${Responsive.w(42, width)}px`,
                height: `${Responsive.h(42, height)}px`
              }}
            >
              <img
                src={img_5}
                srcSet={`${img_5_2}, ${img_5_3}`}
                className="object-contain p-0 flex items-start justify-start flex-col flex-grow-0 "
                alt="Description of the image"
                style={{
                  width: `${Responsive.w(30, width)}px`,
                  height: `${Responsive.h(33.3, height)}px`
                }}
              />
            </div>
          </Card>
        </div>
      )}
      <Table
        loading={isLoading || exportDisabled}
        count={count}
        title={title}
        isEmpty={!data.length}
        customizeAble={true}
        TimeTable={(props) => (
          <div
            // className="absolute top-[30px] right-[-20px] bg-[#f5f3f8] p-[12px]"
            className={`absolute top-[35px] ${
              title === dateRangeMessage ? 'right-[22px]' : 'right-[-20px]'
            } bg-[#f5f3f8] p-[12px]`}
            style={{
              width: `${Responsive.w(572, width)}px`,
              boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)'
            }}
          >
            <ul
              className="py-2 text-sm text-[#202224] flex flex-col items-start justify-start"
              aria-labelledby="dropdownDefaultButton"
              // style={{
              //   width: `${Responsive.w(572, width)}px`,
              // }}
            >
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 mb-3 font-medium w-full"
                  onClick={() => {
                    handleDateOptionClick('All technical support', supportMessage);
                  }}
                >
                  {
                    <FormattedMessage
                      id={supportMessage}
                      defaultMessage={supportMessage}
                    />
                  }
                </a>
              </li>
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 mb-3 font-medium w-full "
                  onClick={() => handleDateOptionClick('Today', todayMessage)}
                >
                  {
                    <FormattedMessage
                      id={todayMessage}
                      defaultMessage={todayMessage}
                    />
                  }
                </a>
              </li>
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 mb-3 font-medium w-full border-solid "
                  onClick={() => handleDateOptionClick('Last 7 days', last7DaysMessage)}
                >
                  {
                    <FormattedMessage
                      id={last7DaysMessage}
                      defaultMessage={last7DaysMessage}
                    />
                  }
                </a>
              </li>
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 font-medium mb-3 border-solid "
                  onClick={() => handleDateOptionClick('Last 30 days', last30DaysMessage)}
                >
                  {
                    <FormattedMessage
                      id={last30DaysMessage}
                      defaultMessage={last30DaysMessage}
                    />
                  }
                </a>
              </li>
              <li className="w-full text-left">
                <a
                  href="#"
                  className="block px-4 py-2 font-medium mb-3 border-solid "
                  onClick={() => {
                    handleDateOptionClick('Date range', dateRangeMessage);
                  }}
                >
                  {
                    <FormattedMessage
                      id={dateRangeMessage}
                      defaultMessage={dateRangeMessage}
                    />
                  }
                </a>
              </li>
            </ul>
          </div>
        )}
        CustomizeTable={() => (
          <>
            {
              <div
                className="absolute top-1 right-0  bg-[#f5f3f8] py-[20px] px-[12px] z-30 "
                style={{
                  width: `${Responsive.w(369, width)}px`,
                  height: `${Responsive.h(760, height)}px`,
                  borderRadius: `${Responsive.sp(16, width)}px`,
                  boxShadow: `0 2px 8px 0 rgba(0, 0, 0, 0.15)`
                }}
              >
                <div
                  className="space-y-4 flex flex-col z-[50]"
                  style={{
                    width: `${Responsive.w(369, width)}px`,
                    height: `${Responsive.h(710, height)}px`
                  }}
                >
                  <div className="grid">
                    <ul
                      className="flex items-start justify-center p-0 flex-row"
                      style={{
                        width: `${Responsive.w(335, width)}px`,
                        height: `${Responsive.h(40, height)}px`,
                        gap: `${Responsive.sp(24, width)}px`
                      }}
                    >
                      {tabs.map((tab: any, index: number) => (
                        <li key={index}>
                          <button
                            className={[
                              'btn-with-icon !rounded-none font-medium bg-transparent nowrap transition-all',
                              currentTab === tab.key
                                ? ` ${
                                    currentDashboard === 'dream'
                                      ? '!text-[#a12f88] border-transparent border-b-[#a12f88]'
                                      : currentDashboard === 'jeras'
                                      ? '!text-[#7b6c96] border-transparent border-b-[#7b6c96]'
                                      : currentDashboard === 'nikah'
                                      ? '!text-[#cf0036] border-transparent border-b-[#cf0036]'
                                      : '!text-[#7b6c96] border-transparent border-b-[#7b6c96]'
                                  } border-b-2 `
                                : '!text-gray-800'
                            ].join(' ')}
                            onClick={() => setCurrentTab(tab.key)}
                          >
                            <span>{tab.name}</span>
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div
                    className="flex items-start justify-start flex-col overflow-y-auto "
                    style={{
                      width: `${Responsive.w(369, width)}px`,
                      height: `${Responsive.h(710, height)}px`
                    }}
                  >
                    {currentTab === 'customize' ? (
                      <ul
                        className="space-y-2 overflow-y-auto"
                        style={{
                          width: `${Responsive.w(369, width)}px`,
                          height: `${Responsive.h(710, height)}px`
                        }}
                      >
                        {keys.map((field: string, index: number) => (
                          <li
                            className="flex flex-row items-start justify-start py-0 px-4 "
                            key={index}
                            style={{
                              width: `${Responsive.w(345, width)}px`,
                              height: `${Responsive.h(40, height)}px`,
                              gap: `${Responsive.sp(12, width)}px`
                            }}
                          >
                            <label
                              htmlFor={field + '-' + index}
                              style={{
                                width: `${Responsive.w(345, width)}px`,
                                height: `${Responsive.h(40, height)}px`,
                                gap: `${Responsive.sp(8, width)}px`
                              }}
                              className="flex text-[#262626] text-left font-[Montserrat] nowrap flex-grow-0 items-center justify-start"
                            >
                              <input
                                type="checkbox"
                                name={field + '-' + index}
                                id={field + '-' + index}
                                className="form-checkbox shrink-0"
                                style={{
                                  width: `${Responsive.w(20, width)}px`,
                                  height: `${Responsive.h(20, height)}px`
                                }}
                                value={field}
                                checked={customize_keys.some((key) => key === field)}
                                onChange={ChangeCustomizeKey}
                              />

                              <p
                                className=" font-medium capitalize flex justify-center items-center"
                                style={{
                                  fontSize: `${Responsive.sp(14, width)}px`
                                }}
                              >
                                {useIntl().formatMessage({
                                  id: field,
                                  defaultMessage: field
                                })}
                              </p>
                            </label>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <FilterFields
                        onSubmit={(val: any) => ChangeFilter(val, 'queries')}
                        filters={filters}
                        onReset={() => ChangeFilter([...initialQueries], 'queries')}
                        dashColor={dashColor}
                        initialQueries={initialQueries}
                      />
                    )}
                  </div>
                </div>
              </div>
            }
          </>
        )}
        RenderBody={({ getRowColor }) => (
          <>
            {data?.map((item: any, index: number) => (
              <tr
                key={index}
                style={{ backgroundColor: getRowColor(index) }}
              >
                <td>{index + 1}</td>

                {isIncluded('name') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.name || 'N/A'}
                  </td>
                )}
                {isIncluded('phoneNumber') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.phoneNumber || 'N/A'}
                  </td>
                )}
                {isIncluded('uid') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.uid || 'N/A'}
                  </td>
                )}
                {isIncluded('balance') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {CurrencyFormatter(item?.balance || 0)}
                  </td>
                )}
                {isIncluded('countryCode') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.countryCode || 'N/A'}
                  </td>
                )}
                {isIncluded('countryISOCode') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.countryISOCode || 'N/A'}
                  </td>
                )}
                {isIncluded('payedBalance') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {CurrencyFormatter(item?.payedBalance || 0)}
                  </td>
                )}
                {isIncluded('ordersNumbers') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.ordersNumbers || 0}
                  </td>
                )}

                {isIncluded('loggedInVia') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.loggedInVia || 'N/A'}
                  </td>
                )}
                {isIncluded('chat') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.chat ? 'Included' : 'N/A'}
                  </td>
                )}
                {isIncluded('voice') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.voice ? 'Included' : 'N/A'}
                  </td>
                )}
                {isIncluded('userType') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.userType || 'N/A'}
                  </td>
                )}
                {isIncluded('accountStatus') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    <p
                      className="flex items-center justify-center"
                      style={{
                        height: '30px',
                        border:
                          item?.accountStatus === 'NotActive'
                            ? 'solid 1px #e84a4d'
                            : item?.accountStatus === 'Active'
                            ? 'solid 1px #28c794'
                            : '',
                        backgroundColor:
                          item?.accountStatus === 'NotActive'
                            ? '#ffe2e2'
                            : item?.accountStatus === 'Active'
                            ? '#ebfeef'
                            : '',
                        color:
                          item?.accountStatus === 'NotActive'
                            ? ' #d91c1f'
                            : item?.accountStatus === 'Active'
                            ? '#228176'
                            : ''
                      }}
                    >
                      {item?.accountStatus || 'N/A'}
                    </p>
                  </td>
                )}
                {isIncluded('userLang') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.userLang || 'N/A'}
                  </td>
                )}
                  {isIncluded('otherConnections') && (
                  <td className="text-center nowrap text-[14px] font-medium text-[#515151] self-stretch">
                    <div
                      className="flex items-center justify-center "
                      style={{
                        borderRadius: `${Responsive.sp(42, width)}px`,
                        gap: `${Responsive.sp(24, width)}px`
                      }}
                    >
                      {item?.providers?.map((provider, index) => {
                        if (provider?.type === 'apple') {
                          return (
                            <button
                              key={index}
                              className={`relative flex items-center justify-center border border-solid border-[${dashColor}] bg-[#f3effa] w-8 h-8`}
                              style={{
                                borderRadius: `${Responsive.sp(42, width)}px`,
                                gap: `${Responsive.sp(8, width)}px`,
                                padding: `${Responsive.sp(4, width)}px`
                              }}
                            >
                              <Icon
                                icon={'devicon:apple'}
                                className="w-5 h-5"
                                style={{
                                  objectFit: 'contain'
                                }}
                              />
                              <div
                                className={`absolute bg-white flex items-center  z-10  h-[49px] rounded-lg gap-2 px-[15px] py-1 transition-all duration-300 ease-linear apple_down`}
                                style={{
                                  width: 'fit-content',
                                  top: '120%',
                                  right: '-95%',
                                  boxShadow: '0 0 6.9px 0 rgba(0, 0, 0, 0.15)'
                                }}
                              >
                                <Icon
                                  icon={'devicon:apple'}
                                  style={{
                                    width: `26px`,
                                    height: `26px`,
                                    objectFit: 'contain'
                                  }}
                                />
                                <p
                                  className="text-center text-[#515151] w-3/4  h-[21px] font-medium"
                                  style={{
                                    lineHeight: 1.5
                                  }}
                                >
                                  {provider?.value}
                                </p>
                              </div>
                            </button>
                          );
                        }
                        // google
                         else if (provider?.type === 'google') {
                          return (
                            <button
                              key={index}
                              className={`relative flex items-center justify-center border border-solid border-[${dashColor}] bg-[#f3effa] w-8 h-8 hover:transition transition ease-in-out delay-150`}
                              style={{
                                borderRadius: `${Responsive.sp(42, width)}px`,
                                gap: `${Responsive.sp(8, width)}px`,
                                padding: `${Responsive.sp(4, width)}px`
                              }}
                            >
                              <Icon
                                icon={'devicon:google'}
                                className="w-5 h-5"
                                style={{
                                  objectFit: 'contain'
                                }}
                              />
                              <div
                                className="absolute bg-white flex items-center  z-10  h-[49px] rounded-lg gap-2 px-[15px] py-1 hover:transition transition ease-in-out delay-150 google_down"
                                style={{
                                  width: 'fit-content',
                                  top: '120%',
                                  right: '-55%',
                                  boxShadow: '0 0 6.9px 0 rgba(0, 0, 0, 0.15)'
                                }}
                              >
                                <Icon
                                  icon={'devicon:google'}
                                  className="flex items-start justify-start"
                                  style={{
                                    width: `26px`,
                                    height: `26px`,
                                    objectFit: 'contain'
                                  }}
                                />
                                <p
                                  className="text-center text-[#515151] h-[21px] font-medium"
                                  style={{
                                    width: 'fit-content',
                                    lineHeight: 1.5
                                  }}
                                >
                                  {provider?.value}
                                </p>
                              </div>
                            </button>
                          );
                        } 
                        // phoneNumber
                         else if (provider?.type === 'phoneNumber') {
                          return (
                            <button
                              key={index}
                              className={`relative flex items-center justify-center border border-solid border-[${dashColor}] bg-[#f3effa] w-8 h-8 hover:transition transition ease-in-out delay-150`}
                              style={{
                                borderRadius: `${Responsive.sp(42, width)}px`,
                                gap: `${Responsive.sp(8, width)}px`,
                                padding: `${Responsive.sp(4, width)}px`
                              }}
                            >
                              <Icon
                                icon={'material-symbols:call'}
                                className="w-5 h-5"
                                style={{
                                  objectFit: 'contain'
                                }}
                              />
                              <div
                                className="absolute bg-white flex items-center  z-10  h-[49px] rounded-lg gap-2 px-[15px] py-1 hover:transition transition ease-in-out delay-150 google_down"
                                style={{
                                  width: 'fit-content',
                                  top: '120%',
                                  right: '-12%',
                                  boxShadow: '0 0 6.9px 0 rgba(0, 0, 0, 0.15)'
                                }}
                              >
                                <Icon
                                  icon={'material-symbols:call'}
                                  className="flex items-start justify-start"
                                  style={{
                                    width: `26px`,
                                    height: `26px`,
                                    objectFit: 'contain'
                                  }}
                                />
                                <p
                                  className="text-center text-[#515151] h-[21px] font-medium"
                                  style={{
                                    width: 'fit-content',
                                    lineHeight: 1.5
                                  }}
                                >
                                  {provider?.value}
                                </p>
                              </div>
                            </button>
                          );
                        } 
                        else {
                          return 'N/A';
                        }
                      })}
                    </div>
                  </td>
                )}
                {isIncluded('languages') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.languages?.join(' | ')}
                  </td>
                )}
                {isIncluded('deviceType') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.deviceType}
                  </td>
                )}
                {isIncluded('isBlocked') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.isBlocked ? 'Yes' : 'No'}
                  </td>
                )}
                {isIncluded('isDeveloper') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.isDeveloper ? 'Yes' : 'No'}
                  </td>
                )}
                {isIncluded('isSupervisor') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    {item?.isSupervisor ? 'Yes' : 'No'}
                  </td>
                )}
                {isIncluded('createdDate') && (
                  <td className="text-center nowrap  text-[14px]   font-medium text-[#515151] self-stretch">
                    <SharedTime date={new Date(item?.utcTime)} />
                  </td>
                )}
                {currentDashboard === 'jeras' && (
                  <td
                    className="flex items-center justify-center  text-center nowrap  text-[14px]  font-medium text-[#515151] self-stretch cursor-pointer"
                    onClick={() => {
                      handelUpdataUser(item.uid);
                    }}
                  >
                    <p className=" flex items-center justify-center rounded-full bg-[#ffdbe3] w-[29.7px]  h-[29.7px] p-0">
                      <Icon
                        icon={'material-symbols:delete-outline'}
                        className="object-contain p-0 w-[9.8px] h-[9.8px] text-[#c43636] "
                      />
                    </p>
                  </td>
                )}
              </tr>
            ))}
          </>
        )}
        RenderHead={() => (
          <>
            <th
              className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
              style={{
                height: `${Responsive.h(24, height)}px`,
                background: dashColor
              }}
            >
              #
            </th>

            {isIncluded('name') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  background: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="name"
                  text={useIntl().formatMessage({
                    id: 'name',
                    defaultMessage: 'name'
                  })}
                  handler={ChangeFilter}
                  sortAble
                />
              </th>
            )}
            {isIncluded('phoneNumber') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  background: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="phoneNumber"
                  text={useIntl().formatMessage({
                    id: 'phoneNumber',
                    defaultMessage: 'phone number'
                  })}
                  handler={ChangeFilter}
                />
              </th>
            )}
            {isIncluded('uid') && (
              <th className="capitalize text-center nowrap  text-[14px] font-medium   self-stretch">
                <SortButton
                  filters={filters}
                  sortKey="uid"
                  text={useIntl().formatMessage({
                    id: 'uid',
                    defaultMessage: 'ID'
                  })}
                  handler={ChangeFilter}
                />
              </th>
            )}
            {isIncluded('balance') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  background: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="balance"
                  text={useIntl().formatMessage({
                    id: 'balance',
                    defaultMessage: 'balance'
                  })}
                  sortAble
                  handler={ChangeFilter}
                />
              </th>
            )}
            {isIncluded('countryCode') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  background: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="countryCode"
                  text={useIntl().formatMessage({
                    id: 'countryCode',
                    defaultMessage: 'country code'
                  })}
                  handler={ChangeFilter}
                />
              </th>
            )}
            {isIncluded('countryISOCode') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  background: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="countryISOCode"
                  text={
                    <FormattedMessage
                      id="countryISOCode"
                      defaultMessage={'country ISO code'}
                    />
                  }
                  handler={ChangeFilter}
                  sortAble
                />
              </th>
            )}

            {isIncluded('payedBalance') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  background: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="payedBalance"
                  text={
                    <FormattedMessage
                      id="payedBalance"
                      defaultMessage={'payed balance'}
                    />
                  }
                  handler={ChangeFilter}
                  sortAble
                />
              </th>
            )}
            {isIncluded('ordersNumbers') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  background: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="ordersNumbers"
                  text={
                    <FormattedMessage
                      id="ordersNumber"
                      defaultMessage={'orders number'}
                    />
                  }
                  handler={ChangeFilter}
                  sortAble
                />
              </th>
            )}
            {isIncluded('loggedInVia') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  background: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="loggedInVia"
                  text={
                    <FormattedMessage
                      id="loggedInVia"
                      defaultMessage={'logged in via'}
                    />
                  }
                  handler={ChangeFilter}
                />
              </th>
            )}
            {isIncluded('chat') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  background: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="chat"
                  text={
                    <FormattedMessage
                      id="chat"
                      defaultMessage={'chat'}
                    />
                  }
                  handler={ChangeFilter}
                />
              </th>
            )}
            {isIncluded('voice') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  background: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="voice"
                  text={
                    <FormattedMessage
                      id="voice"
                      defaultMessage={'voice'}
                    />
                  }
                  handler={ChangeFilter}
                />
              </th>
            )}
            {isIncluded('userType') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  background: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="userType"
                  text={
                    <FormattedMessage
                      id="userType"
                      defaultMessage={'user type'}
                    />
                  }
                  handler={ChangeFilter}
                />
              </th>
            )}
            {isIncluded('accountStatus') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  background: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="accountStatus"
                  text={
                    <FormattedMessage
                      id="accountStatus"
                      defaultMessage={'account status'}
                    />
                  }
                  handler={ChangeFilter}
                />
              </th>
            )}
            {isIncluded('userLang') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  background: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="userLang"
                  text={
                    <FormattedMessage
                      id="userLang"
                      defaultMessage={'user language'}
                    />
                  }
                  handler={ChangeFilter}
                  sortAble
                />
              </th>
            )}
            {isIncluded('otherConnections') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  background: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="userLang"
                  text={
                    <FormattedMessage
                      id="otherConnections"
                      defaultMessage={'Other connections'}
                    />
                  }
                  handler={ChangeFilter}
                  sortAble
                />
              </th>
            )}
            {isIncluded('languages') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  background: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="languages"
                  text={
                    <FormattedMessage
                      id="languages"
                      defaultMessage={'languages'}
                    />
                  }
                  handler={ChangeFilter}
                />
              </th>
            )}
            {isIncluded('deviceType') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  background: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="deviceType"
                  text={
                    <FormattedMessage
                      id="deviceType"
                      defaultMessage={'device type'}
                    />
                  }
                  handler={ChangeFilter}
                />
              </th>
            )}
            {isIncluded('isBlocked') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  background: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="isBlocked"
                  text={
                    <FormattedMessage
                      id="isBlocked"
                      defaultMessage={'is blocked'}
                    />
                  }
                  handler={ChangeFilter}
                />
              </th>
            )}
            {isIncluded('isDeveloper') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  background: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="isDeveloper"
                  text={
                    <FormattedMessage
                      id="isDeveloper"
                      defaultMessage={'is developer'}
                    />
                  }
                  handler={ChangeFilter}
                />
              </th>
            )}
            {isIncluded('isSupervisor') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  background: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="isSupervisor"
                  text={
                    <FormattedMessage
                      id="isSupervisor"
                      defaultMessage={'is supervisor'}
                    />
                  }
                  handler={ChangeFilter}
                />
              </th>
            )}
            {isIncluded('createdDate') && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  background: dashColor
                }}
              >
                <SortButton
                  filters={filters}
                  sortKey="createdDateValue"
                  text={
                    <FormattedMessage
                      id="createdDateValue"
                      defaultMessage={'created date value'}
                    />
                  }
                  handler={ChangeFilter}
                  sortAble
                />
              </th>
            )}
            {currentDashboard === 'jeras' && (
              <th
                className="text-center nowrap  text-[14px] font-medium   self-stretch text-[#fff]"
                style={{
                  height: `${Responsive.h(24, height)}px`,
                  backgroundColor: dashColor
                }}
              >
                {useIntl().formatMessage({
                  id: 'delete',
                  defaultMessage: 'Delete'
                })}
              </th>
            )}
          </>
        )}
        buttonExport={() => ExportXlsx(data, customize_keys)}
        buttonExportAll={() => ExportAllDataXlsx()}
        onApply={(startDate, endDate) => {
          setStart(startDate);
          setEnd(endDate);
        }}
        pagination={{ ...pagination, search_key: filters.search }}
        onNextClick={() => ChangeFilter((pagination?.currentPage || 1) + 1, 'page')}
        onPreviousClick={() => ChangeFilter((pagination?.currentPage || 1) - 1, 'page')}
        ChangePerPageLimit={(e: any) => {
          if (e.key === 'Enter') return ChangeFilter(e.target?.value || 20, 'perPage');
        }}
        searchProps={{
          onKeyDown(e: any) {
            if (e.key === 'Enter' && e.target.value) {
              // Check if the input is a phone number (only numbers and/or '+')
              const isPhoneNumber = /^[+]?[0-9]+$/.test(e.target.value);

              if (isPhoneNumber) {
                // Search in phoneNumber field
                return ChangeFilter([['phoneNumber', '>=', e.target.value + '~']], 'queries');
              } else {
                // Search in name field
                return ChangeFilter([['name', '>=', e.target.value + '~']], 'queries');
              }
            }
          }
        }}
      />
    </div>
  );
}

function TableCustomizeFilterModal({ SendPrams, params, setIsModalOpen, initialQueries }: any) {
  const { keys, customize_keys, filters, data } = useSelector((state: RootState) => state.Users);
  const dispatch = useDispatch();
  const [visible, setVisible] = useState<boolean>(false);
  const { ChangeFilter } = useHooks();

  const handleOpenModal = () => {
    // ChangeFilter('clearSorts', 'sorts');
    // Step 1: Open the modal and set sorts to empty
    SendPrams(initialQueries);
    setIsModalOpen(true);
    setVisible(true);
  };
  const tabs = [
    {
      name: useIntl().formatMessage({ id: 'customize', defaultMessage: 'Customize fields' }),
      key: 'customize'
    },
    { name: useIntl().formatMessage({ id: 'filter', defaultMessage: 'Filter' }), key: 'filter' }
  ];
  const [currentTab, setCurrentTab] = useState<string>('filter');

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const ChangeCustomizeKey = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    dispatch(ChangeCustomizeKeys({ value, checked }));
  }, []);

  return (
    <>
      <div>
        <div className="flex items-center flex-wrap gap-3 justify-between ">
          <RangePicker
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            // applyHandler={() =>
            //   SendPrams([
            //     ['createdDateValue', '==', `${formatStartDay(startDate)}-${formatEndDay(endDate)}`],
            //     ...initialQueries
            //   ])
            // }
          />
          <div className="flex items-center flex-wrap gap-3 ">
            <button
              className="btn-with-icon bg-gray-200 !text-gray-600"
              onClick={() => {
                SendPrams(initialQueries);
                setStartDate(new Date());
                setEndDate(new Date());
              }}
              disabled={initialQueries.length == 0 && params.queries.length == 0}
            >
              {useIntl().formatMessage({
                id: 'clearSearchAndFilters',
                defaultMessage: 'Clear search and filters'
              })}
            </button>
            {/* <button
              className="btn-with-icon bg-gray-200 !text-gray-600"
              onClick={() => ExportXlsx(data, customize_keys)}
              disabled={exportDisabled}
            >
              <Icon
                icon="bi:filetype-xlsx"
                width="18"
                height="18"
              />
              <span>
                {useIntl().formatMessage({
                  id: 'exportExcel',
                  defaultMessage: 'Export Excel'
                })}
              </span>
            </button> */}
            <button
              className="btn-with-icon bg-gray-800 text-white"
              onClick={handleOpenModal}
            >
              <Icon
                icon="majesticons:filter-line"
                width="18"
                height="18"
              />
              <span>
                {useIntl().formatMessage({
                  id: 'filterAndCustomizeTable',
                  defaultMessage: 'Filter & Customize table'
                })}
              </span>
            </button>
          </div>
        </div>
      </div>
      <SideModal
        title={useIntl().formatMessage({
          id: 'filterAndCustomizeTable',
          defaultMessage: 'Filter & Customize table'
        })}
        visible={visible}
        handleClose={() => {
          setVisible(false);
          setIsModalOpen(false);
        }}
        size="max-w-screen-sm"
      >
        <div className="space-y-4 flex flex-col overflow-y-auto">
          <div className="border-b border-b-gray-200 overflow-x-auto grid">
            <ul className="inline-flex">
              {tabs.map((tab: any, index: number) => (
                <li key={index}>
                  <button
                    className={[
                      'btn-with-icon !rounded-none font-medium bg-transparent transition-all',
                      currentTab === tab.key
                        ? '!text-gray-800 border-b-2 border-b-gray-800'
                        : '!text-gray-500'
                    ].join(' ')}
                    onClick={() => setCurrentTab(tab.key)}
                  >
                    <span>{tab.name}</span>
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex-1 grid overflow-y-auto">
            {currentTab === 'customize' ? (
              <ul className="space-y-2">
                {keys.map((field: string, index: number) => (
                  <li key={index}>
                    <label
                      htmlFor={field + '-' + index}
                      className="flex gap-3 items-center py-3 px-4 rounded-lg border border-gray-200  cursor-pointer"
                    >
                      <input
                        type="checkbox"
                        name={field + '-' + index}
                        id={field + '-' + index}
                        className="form-checkbox shrink-0"
                        value={field}
                        checked={customize_keys.some((key) => key === field)}
                        onChange={ChangeCustomizeKey}
                      />

                      <p className="text-sm font-medium capitalize flex-1">
                        {useIntl().formatMessage({
                          id: field,
                          defaultMessage: field
                        })}
                      </p>
                    </label>
                  </li>
                ))}
              </ul>
            ) : (
              <FilterFields
                setIsModalOpen={setIsModalOpen}
                onSubmit={SendPrams}
                filters={filters}
                onReset={() => {
                  SendPrams([]);
                  setIsModalOpen(false);
                }}
                handleClose={setVisible}
              />
            )}
          </div>
        </div>
      </SideModal>
    </>
  );
}
function FilterFields({
  onSubmit,
  filters: values,
  handleClose,
  onReset,
  setIsModalOpen,
  dashColor,
  initialQueries
}: any) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const periods = usePeriods();
  const { width, height } = useWindowDimensions();
  const [filters, setFilters] = useState<any>(values.queries || []);
  const [selectedField, setSelectedField] = useState<Fields>();
  const [query, setQuery] = useState<any[]>([filters?.at(-1)]);
  const [selectedFields, setSelectedFields] = useState<any[]>([]);
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  const { user } = useSelector((state: RootState) => state.auth);

  const handleOnClearClick = () => {
    onReset();
    setFilters([]);
    setSelectedFields([]);
  };

  function findAndReplaceOrAdd(filters, key, operator, value) {
    const index = filters.findIndex(([_key, _operator]) => _key === key && _operator === operator);

    if (index !== -1) {
      // If the filter exists, replace it
      filters[index] = [key, operator, value];
    } else {
      // If the filter doesn't exist, add it
      filters.push([key, operator, value]);
    }

    return filters;
  }

  const ChangeFilters = useCallback(
    (key: string, type?: any) => (e: any) => {
      // Create a copy of the current filters
      let result: any[] = [...filters];
      const value = type === 'checkbox' ? e.target.checked : e.target.value;

      if (selectedFields.includes(key)) {
        switch (key) {
          case 'balance':
            result = findAndReplaceOrAdd(result, key, '>=', value);
            break;
          case 'isClient':
            result = findAndReplaceOrAdd(result, key, '>', 0);
            break;
          case 'ordersNumbers':
            result = findAndReplaceOrAdd(result, key, '>=', value);
            break;
          case 'accountStatus':
            result = findAndReplaceOrAdd(result, key, '==', value);
            break;
          case 'countryCode':
            result = findAndReplaceOrAdd(result, key, '==', value);
            break;
          case 'date':
            const { from, to }: any = getDates(value);
            result = findAndReplaceOrAdd(result, 'createdDateValue', '==', from + '-' + to);
            break;
          default:
            result = findAndReplaceOrAdd(result, key, '==', value);
            break;
        }
      } else {
        result = removeFilter(key);
      }

      setFilters(result);
      setQuery(result);
    },
    [filters, selectedFields]
  );
  const handleSubmit = (query) => {
    const finalQuery = query.filter(q => q[0] !== 'userLang');
    
    if ( initialQueries?.length == 2) {
      onSubmit([...initialQueries, ...finalQuery]);
    }else{
      onSubmit(query);
    }
    
  };
  function removeFilter(key: string) {
    // Create a copy of the filters without the filter for the given key
    return filters.filter(([k]: any) => k !== key);
  }

  const findQuery = useCallback(
    (key: string, operator: string, value: any) => {
      const queries: any[] = filters;
      let result: any[];
      const query = queries?.find(
        ([_key, _operator]: string[]) => _key === key && _operator === operator
      );

      if (query) {
        const filter = queries?.filter(([k]) => k !== key);
        result = new Array().concat(filter, [[key, operator, value]]);
      } else {
        result = new Array().concat(queries, [[key, operator, value]]);
      }

      return result;
    },
    [filters]
  );

  const findValue = useCallback(
    (key: string) => {
      let value: any;
      const query = filters?.find(([queryKey]: any) => key === queryKey);
      if (!query) return;

      value = query?.[2];
      console.log('findValue', value);

      return value;
    },
    [filters, user]
  );

  function onFieldChange(e: ChangeEvent<HTMLInputElement>) {
    const field = e.target.value;
  
    // تجاوز `userLang` إذا كان موجودًا بالفعل في initialQueries
    if ( initialQueries?.length == 2   && field == 'userLang' ) {
      return;
    }
  
    setSelectedFields((prevSelectedFields) => {
      const isSelected = prevSelectedFields.includes(field);
      if (isSelected) {
        return prevSelectedFields.filter((selected) => selected !== field);
      } else {
        return [...prevSelectedFields, field];
      }
    });
  }
  

  useEffect(() => {
    console.log('filters:', filters);
  }, [filters]);

  useEffect(() => {
    setSelectedFields(filters.map(([key]) => key));
  }, [filters]);
  console.log('selectedFields', selectedFields);

  return (
    <form
      className="space-y-3 z-20"
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(query);
      }}
    >
      <div
        className="flex flex-col items-start justify-start p-0"
        style={{
          width: `${Responsive.w(330, width)}px`,
          height: `${Responsive.h(70, height)}px`,
          gap: `${Responsive.sp(12, width)}px`
        }}
      >
        <div
          className="flex items-center justify-start p-0"
          style={{
            width: `${Responsive.w(88, width)}px`,
            height: `${Responsive.h(22, height)}px`,
            gap: `${Responsive.sp(11, width)}px`
          }}
        >
          <input
            type="checkbox"
            name="filter-by-group"
            onChange={onFieldChange}
            value="balance"
            className="form-checkbox form-outline shrink-0"
            checked={selectedFields.includes('balance')}
          />
          <label
            className="font-[Montserrat] text-[#262626]"
            style={{
              width: `${Responsive.w(57, width)}px`,
              height: `${Responsive.h(22, height)}px`,
              fontSize: `${Responsive.sp(14, width)}px`
            }}
            htmlFor="balance-amount"
          >
            <FormattedMessage
              id="balance"
              defaultMessage={'Balance'}
            />
          </label>
        </div>

        <input
          type="number"
          name="balance-amount"
          id="balance-amount"
          placeholder="0"
          min={0}
          onChange={ChangeFilters('balance')}
          defaultValue={findValue('balance')}
          disabled={!selectedFields.includes('balance')}
          readOnly={!selectedFields.includes('balance')}
          className="flex items-center outline-none justify-start py-0 px-[8px] ml-auto bg-white border-solid border-[1px] border-[#afafaf]"
          style={{
            width: `${Responsive.w(299, width)}px`,
            height: `${Responsive.h(36, height)}px`,
            gap: `${Responsive.sp(10, width)}px`,
            borderRadius: `${Responsive.sp(4, width)}px`
          }}
        />
      </div>

      <div
        className="flex flex-col items-start justify-start p-0"
        style={{
          width: `${Responsive.w(330, width)}px`,
          height: `${Responsive.h(70, height)}px`,
          gap: `${Responsive.sp(12, width)}px`
        }}
      >
        <div
          className="flex flex-row items-center justify-start p-0"
          style={{
            width: `${Responsive.w(175, width)}px`,
            height: `${Responsive.h(22, height)}px`,
            gap: `${Responsive.sp(11, width)}px`
          }}
        >
          <input
            type="checkbox"
            name="filter-by-group"
            onChange={onFieldChange}
            value="ordersNumbers"
            className="form-checkbox form-outline shrink-0"
            checked={selectedFields.includes('ordersNumbers')}
          />

          <label
            className="font-[Montserrat] nowrap text-[#262626]"
            style={{
              width: `${Responsive.w(144, width)}px`,
              height: `${Responsive.h(22, height)}px`,
              fontSize: `${Responsive.sp(14, width)}px`
            }}
            htmlFor="orders-amount"
          >
            <FormattedMessage
              id="ordersNumbers"
              defaultMessage={'Orders number'}
            />
          </label>
        </div>
        <input
          type="number"
          name="orders-amount"
          id="orders-amount"
          placeholder="0"
          min={0}
          onChange={ChangeFilters('ordersNumbers')}
          disabled={!selectedFields.includes('ordersNumbers')}
          defaultValue={findValue('ordersNumbers')}
          readOnly={!selectedFields.includes('ordersNumbers')}
          className="flex items-center outline-none justify-start py-0 px-[8px] ml-auto bg-white border-solid border-[1px] border-[#afafaf]"
          style={{
            width: `${Responsive.w(299, width)}px`,
            height: `${Responsive.h(36, height)}px`,
            gap: `${Responsive.sp(10, width)}px`,
            borderRadius: `${Responsive.sp(4, width)}px`
          }}
        />
      </div>

      <div
        className="flex flex-col items-start justify-start p-0"
        style={{
          width: `${Responsive.w(330, width)}px`,
          height: `${Responsive.h(70, height)}px`,
          gap: `${Responsive.sp(12, width)}px`
        }}
      >
        <div
          className="flex items-center justify-start p-0"
          style={{
            width: `${Responsive.w(175, width)}px`,
            height: `${Responsive.h(22, height)}px`,
            gap: `${Responsive.sp(11, width)}px`
          }}
        >
          <input
            type="checkbox"
            name="filter-by-group"
            onChange={onFieldChange}
            value="countryCode"
            className="form-checkbox form-outline shrink-0"
            checked={selectedFields.includes('countryCode')}
          />

          <label
            className="font-[Montserrat] nowrap text-[#262626]"
            style={{
              width: `${Responsive.w(144, width)}px`,
              height: `${Responsive.h(22, height)}px`,
              fontSize: `${Responsive.sp(14, width)}px`
            }}
            htmlFor="phone-number"
          >
            {useIntl().formatMessage({
              id: 'countryCode',
              defaultMessage: 'country code'
            })}
          </label>
        </div>
        <input
          type="tel"
          name="phone-number"
          id="phone-number"
          placeholder="Country Code"
          onChange={ChangeFilters('countryCode')}
          defaultValue={findValue('countryCode')}
          disabled={!selectedFields.includes('countryCode')}
          readOnly={!selectedFields.includes('countryCode')}
          className="flex items-center outline-none justify-start py-0 px-[8px] ml-auto bg-white border-solid border-[1px] border-[#afafaf]"
          style={{
            width: `${Responsive.w(299, width)}px`,
            height: `${Responsive.h(36, height)}px`,
            gap: `${Responsive.sp(10, width)}px`,
            borderRadius: `${Responsive.sp(4, width)}px`
          }}
        />
      </div>
      <div
        className="flex flex-col items-start justify-start p-0"
        style={{
          width: `${Responsive.w(330, width)}px`,
          height: `${Responsive.h(70, height)}px`,
          gap: `${Responsive.sp(12, width)}px`
        }}
      >
        <div
          className="flex items-center justify-start p-0"
          style={{
            width: `${Responsive.w(175, width)}px`,
            height: `${Responsive.h(22, height)}px`,
            gap: `${Responsive.sp(11, width)}px`
          }}
        >
          <input
            type="checkbox"
            name="filter-by-group"
            onChange={onFieldChange}
            value="accountStatus"
            className="form-checkbox form-outline shrink-0"
            checked={selectedFields.includes('accountStatus')}
          />

          <label
            className="font-[Montserrat] nowrap text-[#262626]"
            style={{
              width: `${Responsive.w(144, width)}px`,
              height: `${Responsive.h(22, height)}px`,
              fontSize: `${Responsive.sp(14, width)}px`
            }}
            htmlFor="account-status"
          >
            <FormattedMessage
              id="accountStatus"
              defaultMessage={'Account status'}
            />
          </label>
        </div>
        <select
          name="account-status"
          id="account-status"
          className="flex items-center outline-none justify-start py-0 px-[8px] ml-auto bg-white border-solid border-[1px] border-[#afafaf]"
          style={{
            width: `${Responsive.w(299, width)}px`,
            height: `${Responsive.h(36, height)}px`,
            gap: `${Responsive.sp(10, width)}px`,
            borderRadius: `${Responsive.sp(4, width)}px`
          }}
          onChange={ChangeFilters('accountStatus')}
          defaultValue={findValue('accountStatus') || ''}
          disabled={!selectedFields.includes('accountStatus')}
        >
          <optgroup label="Status">
            <option
              value=""
              disabled
            >
              <FormattedMessage
                id="selectedStatus"
                defaultMessage={'Select status'}
              />
            </option>

            <option value="Active">
              <FormattedMessage
                id="active"
                defaultMessage={'Active'}
              />
            </option>
            <option value="NotActive">
              <FormattedMessage
                id="inactive"
                defaultMessage={'Inactive'}
              />
            </option>
          </optgroup>
        </select>
      </div>
      {/* lang */}
      <div
        className="flex flex-col items-start justify-start p-0"
        style={{
          width: `${Responsive.w(330, width)}px`,
          height: `${Responsive.h(70, height)}px`,
          gap: `${Responsive.sp(12, width)}px`
        }}
      >
        <div
          className="flex items-center justify-start p-0"
          style={{
            width: `${Responsive.w(175, width)}px`,
            height: `${Responsive.h(22, height)}px`,
            gap: `${Responsive.sp(11, width)}px`
          }}
        >
          <input
            type="checkbox"
            name="filter-by-group"
            onChange={onFieldChange}
            value="userLang"
            className="form-checkbox form-outline shrink-0"
            checked={selectedFields.includes('userLang')}
          />
          <label
            className="font-[Montserrat] nowrap text-[#262626]"
            style={{
              width: `${Responsive.w(144, width)}px`,
              height: `${Responsive.h(22, height)}px`,
              fontSize: `${Responsive.sp(14, width)}px`
            }}
            htmlFor="user-language"
          >
            <FormattedMessage
              id="userLang"
              defaultMessage={'User language'}
            />
          </label>
        </div>
        <select
          name="user-language"
          id="user-language"
          className="flex items-center outline-none justify-start py-0 px-[8px] ml-auto bg-white border-solid border-[1px] border-[#afafaf]"
          style={{
            width: `${Responsive.w(299, width)}px`,
            height: `${Responsive.h(36, height)}px`,
            gap: `${Responsive.sp(10, width)}px`,
            borderRadius: `${Responsive.sp(4, width)}px`
          }}
          onChange={ChangeFilters('userLang')}
          value={findValue('userLang') || ''}
          disabled={
            !selectedFields.includes('userLang') ||
            initialQueries.map((q) => q[0]).includes('userLang')
          }
        >
          <optgroup label="Status">
            <option value="">
              {useIntl().formatMessage({
                id: 'selectLanguage',
                defaultMessage: 'Select language'
              })}
            </option>
            {user.Language.map((lang) => (
              <>
                {lang == 'English' && (
                  <option value="en">
                    {useIntl().formatMessage({
                      id: 'en',
                      defaultMessage: 'English'
                    })}
                  </option>
                )}
                {lang == 'Arabic' && (
                  <option value="ar">
                    {useIntl().formatMessage({
                      id: 'ar',
                      defaultMessage: 'العربية'
                    })}
                  </option>
                )}
                {lang == 'French' && (
                  <option value="fr">
                    {useIntl().formatMessage({
                      id: 'fr',
                      defaultMessage: 'français'
                    })}
                  </option>
                )}
                {currentDashboard != 'jeras' && lang == 'Bahasa Indonesia' && (
                  <option value="id">
                    {useIntl().formatMessage({
                      id: 'id',
                      defaultMessage: 'bahasa Indonesiacode'
                    })}
                  </option>
                )}
              </>
            ))}
          </optgroup>
        </select>
      </div>

      <div
        className="flex flex-col items-start justify-start p-0"
        style={{
          width: `${Responsive.w(330, width)}px`,
          height: `${Responsive.h(70, height)}px`,
          gap: `${Responsive.sp(12, width)}px`
        }}
      >
        <div
          className="flex items-center justify-start p-0"
          style={{
            width: `${Responsive.w(175, width)}px`,
            height: `${Responsive.h(22, height)}px`,
            gap: `${Responsive.sp(11, width)}px`
          }}
        >
          <input
            type="checkbox"
            name="filter-by-group"
            onChange={onFieldChange}
            value="date"
            className="form-checkbox form-outline shrink-0"
            checked={selectedFields.includes('date')}
          />

          <label
            className="font-[Montserrat] nowrap text-[#262626]"
            style={{
              width: `${Responsive.w(144, width)}px`,
              height: `${Responsive.h(22, height)}px`,
              fontSize: `${Responsive.sp(14, width)}px`
            }}
            htmlFor="date"
          >
            <FormattedMessage
              id="date"
              defaultMessage={'Date'}
            />
          </label>
        </div>
        <select
          name="date"
          id="date"
          className="flex items-center outline-none justify-start py-0 px-[8px] ml-auto bg-white border-solid border-[1px] border-[#afafaf]"
          style={{
            width: `${Responsive.w(299, width)}px`,
            height: `${Responsive.h(36, height)}px`,
            gap: `${Responsive.sp(10, width)}px`,
            borderRadius: `${Responsive.sp(4, width)}px`
          }}
          onChange={ChangeFilters('date')}
          defaultValue={findValue('date') || ''}
          disabled={!selectedFields.includes('date')}
        >
          <optgroup label="Periods">
            <option
              value=""
              disabled
            >
              <FormattedMessage
                id="selectPeriod"
                defaultMessage={'Select period'}
              />
            </option>

            {usePeriods().map((period: any, index: number) => (
              <option value={period.value}>{period.label}</option>
            ))}
          </optgroup>
        </select>
      </div>

      <div
        className="flex items-center justify-start p-0"
        style={{
          width: `${Responsive.w(175, width)}px`,
          height: `${Responsive.h(22, height)}px`,
          gap: `${Responsive.sp(11, width)}px`
        }}
      >
        <input
          type="checkbox"
          name="filter-by-group"
          onChange={onFieldChange}
          value="isClient"
          className="form-checkbox form-outline shrink-0"
          checked={selectedFields.includes('isClient')}
        />
        <label
          className="font-[Montserrat] nowrap text-[#262626]"
          style={{
            width: `${Responsive.w(144, width)}px`,
            height: `${Responsive.h(22, height)}px`,
            fontSize: `${Responsive.sp(14, width)}px`
          }}
          htmlFor="isClient"
        >
          <FormattedMessage
            id="isClient"
            defaultMessage={'isClient'}
          />
        </label>
        <input
          className="form-checkbox form-outline shrink-0"
          onChange={ChangeFilters('isClient', 0)}
          defaultChecked={findValue('isClient') == 0}
          disabled={!selectedFields.includes('isClient')}
          type="checkbox"
          name="isClient"
          id=""
        />
        {/* <select
          name="isClient"
          id="isEnglish"
          className="flex items-center outline-none justify-start py-0 px-[8px] ml-auto bg-white border-solid border-[1px] border-[#afafaf]"
          style={{
            width: `${Responsive.w(299, width)}px`,
            height: `${Responsive.h(36, height)}px`,
            gap: `${Responsive.sp(10, width)}px`,
            borderRadius: `${Responsive.sp(4, width)}px`
          }}
          onChange={ChangeFilters('isClient')}
          defaultValue={findValue('isClient') || ''}
          disabled={!selectedFields.includes('isClient')}
        >
          <optgroup label="Status">
          <option value="">
              {useIntl().formatMessage({
                id: 'selectisClient',
                defaultMessage: 'Select isClient'
              })}
            </option>
            <option value={0}>
              {useIntl().formatMessage({
                id: 'true',
                defaultMessage: 'true'
              })}
            </option>
          </optgroup>
        </select> */}
      </div>

      <div
        className="flex justify-between items-center"
        style={{
          width: `${Responsive.w(303, width)}px`,
          height: `${Responsive.h(42, height)}px`,
          gap: `${Responsive.sp(10, width)}px`,
          borderRadius: `${Responsive.sp(4, width)}px`,
          padding: `${Responsive.sp(10, width)}px`
        }}
      >
        <button
          className="btn-with-icon font-semibold nowrap"
          type="submit"
          style={{
            width: `${Responsive.w(104, width)}px`,
            height: `${Responsive.h(42, height)}px`,
            borderRadius: `${Responsive.sp(4, width)}px`,
            fontSize: `${Responsive.sp(14, width)}px`,
            backgroundColor: dashColor
          }}
        >
          <span
            style={{
              width: `${Responsive.w(84, width)}px`,
              height: `${Responsive.h(22, height)}px`,
              fontSize: `${Responsive.sp(14, width)}px`
            }}
          >
            <FormattedMessage
              id="applyfilter"
              defaultMessage={'Apply Filter'}
            />
          </span>
        </button>

        <button
          className="btn-with-icon bg-gray-200 !text-gray-600"
          type="reset"
          onClick={handleOnClearClick}
        >
          <span>
            <FormattedMessage
              id="clear"
              defaultMessage={'Clear'}
            />
          </span>
        </button>
      </div>
    </form>
  );
}
function Spinner() {
  const currentDashboard = useSelector((state: RootState) => state.Dashboard.dashboard);
  let dashColor: string;
  switch (currentDashboard) {
    case 'dream':
      dashColor = '#a12f88';
      break;
    case 'nikah':
      dashColor = '#cf0036';
      break;
    case 'jeras':
      dashColor = '#7b6c96';
      break;
    default:
      dashColor = '#a12f88';
      break;
  }
  return (
    <>
      <div className="shrink-0 flex flex-col justify-center items-center w-full h-full">
        <Icon
          icon="svg-spinners:3-dots-fade"
          width={50}
          height={25}
          className=""
          style={{
            color: dashColor
          }}
        />
      </div>
    </>
  );
}

